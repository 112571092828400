import React from 'react'
import LineupCreatePage from '../../../../components/pages/StarTutoring/LineupCreatePage'
import StarTutoringLayout from '../../../../components/pages/StarTutoring/StarTutoringLayout'

interface IProps {
  path: string
  params: {
    id: string
  }
}

const LineupPage = (props: IProps) => {
  return <StarTutoringLayout path={props.path}>
    <LineupCreatePage lineupId={props.params.id} />
  </StarTutoringLayout>
}

export default LineupPage
